/* layout */
#main #content {
  margin-top: 0;
}
.container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
  width: 98.6%;
}
.front .sidebar{
  margin-top: 15px;
}
.one-sidebar.sidebar-first #content{
  padding-left:2%;
}
#logo-wrapper{
  width:47%;
}
.region-masthead{
  margin-top: 15px;
  float: right;
  margin-left: 0 !important;
  width:53% !important;
}
.region-navigation{
  position: relative;
  z-index: 10;
  float: left;
}
.row-flex {
  @include row();
}
