@media (min-width: 1200px) {
  .container,
  .navbar-static-top .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 1170px;
  }
  .front #main #content{
    padding:0 .3%;
  }
}
@media (max-width: 1174px){
  #logo-wrapper {
    width: 30%;
    float: left;
  }
  .region-masthead{width: 68%!important}
  #tagline{margin: 5px}
  #logo img{height:48px;}
  #logo{margin: 10px 5px;clear: both;}
}
@media (max-width: 979px) and (min-width: 768px){
  .btn-navbar {
    display: none;
  }
  .navbar .nav-collapse {
    clear: none;
  }
  .nav-collapse {
    height: auto;
    overflow: auto;
  }
  .navbar .nav {
    float: left;
    margin: 0 10px 0 0;
  }
  .navbar .brand {
    margin-left: -20px;
    padding: 8px 20px 12px;
  }
  .navbar .dropdown-menu:before, .navbar .dropdown-menu:after {
    display: block;
  }
  .navbar .nav > li > a, .navbar .dropdown-menu a {
    border-radius: 0;
    color: #999999;
    font-weight: normal;
    padding: 10px 8px 8px;
  }
  .navbar .nav > li {
    float: left;
  }
  .navbar .dropdown-menu {
    background-clip: padding-box;
    background-color: #FFFFFF;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5px 5px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    display: none;
    float: left;
    left: 0;
    list-style: none outside none;
    margin: 0;
    min-width: 160px;
    padding: 4px 0;
    position: absolute;
    top: 100%;
    z-index: 1000;
  }
  .navbar-form, .navbar-search {
    border:none;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1) inset, 0 1px 0 rgba(255, 255, 255, 0.1);
    float: left;
    margin-bottom: 0;
    margin-top:6px;
    padding: 9px 15px;
  }

  .navbar .nav.pull-right {
    float: right;
    margin-left: auto;
  }
  .nav-collapse, .nav-collapse.collapse {
    overflow: visible;
    height: auto;
  }
  .navbar .btn-navbar {
    display: none;
  }
  #social-csusm-wrapper{
    width: auto;
  }
  #social-csusm-wrapper #get-social{
    margin: 0;
    width:100px;
    float: right;
  }
  #social-csusm-wrapper #csusm-menu{
    margin: 0;
    width: 90px;
    float: right;
  }
  #main-menu{
    margin-left: 0;
  }
  .container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
    width: auto;
  }
}

@media (max-width: 870px) and (min-width: 768px){
  #block-search-form {
    clear: both;
    margin-top: 10px;
    margin-right: -26px;
  }
}
@media (max-width: 767px) {
  body{
    font-size: 13px
  }
  .view-id-cevent_base .item-list ul{
    min-width: 200px;
  }
  #navbar #block-user-login {
    margin-bottom: 0
  }
  #navbar #block-nice-menus-1 .nav {
    margin: 0
  }
  .region-masthead {
    margin: 5px;float: none;
  }
  .region-navigation {
    float: none;
  }
  #block-search-form {
    float: left;
  }
  #edit-search-block-form--2 {
    width: auto;min-width: 145px;
  }
  .region-masthead .block-service-links{
    display: none;}
  #navbar .navbar-inner{
    padding: 0;
    height: 44px;
  }
  #navbar #site-nav{
    position: absolute;
    background: #fff;
    width: 100%;
    z-index: 100;
    margin-top: 54px;
    background-color: #f2f2f2;
  }
  ul.nice-menu li.menuparent ul, #header-region ul.nice-menu li.menuparent ul {
    display: block !important;
    visibility: visible !important;
    border: none;
  }
  ul.nice-menu ul.dropdown-menu li {
    float: none;
  }
  ul.nice-menu-down .menuparent a {
    color: #555;
  }
  #block-search-form form {
    margin: 0}
  .region-masthead{
    width: 100% !important;clear: both;float: right !important;}
  #logo-wrapper{
    width: 157px;
    margin:0 0 0 0;
  }
  .row-fluid .region-masthead{
    margin: 0;
    padding: 0;
    min-height: 0;
  }
  #logo img{
    height: 45px;
  }
  #tagline{
    margin-top: 0;
    margin-left: 8px;
    width:200px;
  }
  #block-search-form {
    position: absolute;
    top: 1px;
    right: 6px;
  }
  .not-logged-in #block-search-form{
    right: 44px;
  }
  #toboggan-container{
    text-align: left;
  }
  div.toboggan-login-link-container {
    width: auto;
    text-align: left;
  }
  #block-user-login .btn{
    padding: 2px 4px 0;
    font-size: 12px;
    text-align: center;
    height: 32px;
    overflow: hidden;
  }
  #block-user-login .btn .icon-user{
    padding:4px !important;
    font-size: 22px;
    color:#efefef;
  }
  #mobile-site-nav{
    position: absolute;
    top:4px;
    left:auto;
    right: 53px;
    margin:0;
    padding:8px 7px;
    height: 18px;
    overflow: hidden;
  }
  .not-logged-in #mobile-site-nav{
    right:104px;
  }
  #mobile-site-search{
    padding: 6px 8px 4px;
    float: right;
  }
  #mobile-site-search .icon-search{
    font-size:20px;
    padding: 6px 1px !important;
    height: 25px;
    overflow: hidden;
  }
  #search-collapse{
    background-color: #FFF;
    top: 4px;
    z-index: 100;
  }
  #search-collapse .form-item {
    display: block;
    padding: 20px 0 20px 10px;
    background: #f2f2f2;
    margin:0;
  }
  #search-collapse .form-wrapper {
    display: block;
    padding: 20px 20px 20px 0;
    background: #f2f2f2;
  }
  #search-collapse .form-submit{
    margin: 0;
  }
  #toboggan-login-link, #mobile-site-nav{
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #49afcd;
    background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
    background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
    background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
    background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f96b4', GradientType=0);
    border-color: #2f96b4 #2f96b4 #1f6377;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  }
  #toboggan-login-link:hover,
  #mobile-site-nav:hover{
    color: #FFF;
    background-color: #2F96B4;
  }
  #navbar #block-user-login{
    position: absolute;
    top: 4px;
    right:6px;
    z-index: 100;
  }
  .navbar .btn-navbar .icon-bar {
    display: block;
    width: 8px;
    height: 1px;
    background-color: #efefef !important;
  }
  #block-views-cevent-base-block-2 {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  #block-views-front-slideshow-block{
    margin: 0;
  }
}

