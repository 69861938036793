/* general typography */
p {
  line-height: 20px !important;
  margin: 0 0 10px !important; }

blockquote {
  border-left: none; }

blockquote p {
  font-size: 14px;
  font-weight: normal;
  line-height: normal; }

a:hover {
  text-decoration: none !important; }

/* Merge Bootstrap styles back in */
#crm-container fieldset legend {
  display: block !important;
  width: 100% !important;
  padding: 0 !important;
  font-size: 17px !important;
  line-height: 32px !important;
  color: #333 !important;
  border: none !important;
  font-weight: bold !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.icon {
  color: #2786C2; }

.item-list .unstyled ul li {
  margin: 0 0 10px;
  padding: 0; }

td.label {
  display: table-cell !important;
  color: #444; }

label {
  color: #444; }

.crm-section.group_selection-section .label {
  color: #444; }

.crm-accordion-wrapper {
  min-height: 20px;
  padding: 0px;
  background-color: whiteSmoke;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

/* replace JQuery UI icons */
/* to override jqueryui icons for font icons */
[class^="icon-"],
[class*=" icon-"] {
  background: none !important;
  background-image: none !important;
  text-indent: 0 !important;
  padding: .2em .6em 0 .2em !important; }

/* general form tweaks */
.form-item .description {
  max-width: 660px; }

.resizable-textarea textarea {
  width: 100%; }

.form-item-timezone select {
  width: auto; }

/* messages/alerts */
.messages.alert {
  margin: 0 0 20px !important;
  border: 1px solid #FBEED5;
  max-width: 660px; }

.alert-info {
  color: #3A87AD !important;
  background-color: #D9EDF7 !important;
  border-color: #BCE8F1 !important; }

#crm-container #help {
  font-size: 14px;
  margin: 0 0 20px;
  padding: 8px 35px 8px 14px; }

/* some typography */
.page-header #page-title {
  font-size: 28px;
  font-weight: normal;
  margin-top: 20px;
  padding-left: 5px; }

.page-training-view .page-header, .section-training.page-views .page-header {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0; }

/* breadcrumb */
.breadcrumb {
  padding: 8px 15px;
  margin: 10px 0 20px;
  background-color: #f5f5f5; }

/* page-specific header */
#header h1 {
  font-size: 24.5px; }

.front .page-header {
  display: none; }

/* typography tweaks */
h1, h1.title, h2.node-title {
  font-size: 32px;
  color: #595959;
  margin-top: 30px; }

h2 {
  font-size: 18.5px; }

.well h2 {
  font-weight: normal;
  color: #555;
  line-height: normal;
  margin-left: 5px; }

/* layout */
#main #content {
  margin-top: 0; }

.container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
  width: 98.6%; }

.front .sidebar {
  margin-top: 15px; }

.one-sidebar.sidebar-first #content {
  padding-left: 2%; }

#logo-wrapper {
  width: 47%; }

.region-masthead {
  margin-top: 15px;
  float: right;
  margin-left: 0 !important;
  width: 53% !important; }

.region-navigation {
  position: relative;
  z-index: 10;
  float: left; }

.row-flex {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start; }

/* site header */
#logo {
  margin: 10px 0 5px 10px;
  max-width: 205px; }

#tagline {
  float: left;
  display: none;
  margin-top: 46px;
  margin-left: -17px; }

#logo a {
  float: left; }

#logo img {
  height: auto;
  max-width: 100%; }

#navbar .navbar-inner {
  padding-bottom: 0;
  background-color: #C7E3F8;
  /* cae1f3 */
  background-image: -webkit-linear-gradient(top, #C7E3F8, #EDF6FC);
  background-image: -moz-linear-gradient(top, #C7E3F8, #EDF6FC);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#C7E3F8), to(#EDF6FC));
  background-image: -webkit-linear-gradient(top, #C7E3F8, #EDF6FC);
  background-image: -o-linear-gradient(top, #C7E3F8, #EDF6FC);
  background-image: linear-gradient(to bottom, #C7E3F8, #EDF6FC);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffC7E3F8', endColorstr='#ffEDF6FC', GradientType=0); }

.navbar .btn-navbar {
  position: absolute;
  right: 0;
  top: 70px; }

#navbar .container {
  position: relative; }

#navbar .brand {
  height: 86px;
  padding: 15px 20px 0 30px;
  display: block;
  overflow: hidden; }

#navbar .brand img {
  height: 96px;
  width: 551px; }

#search-block-form {
  margin-bottom: 5px; }

#search-block-form .form-actions {
  padding: 0; }

#search-block-form .form-submit {
  margin-bottom: 6px;
  color: #5E83A8;
  background: #F0F5FA; }

#edit-search-block-form--2 {
  background: #fafafa;
  max-width: 165px; }

#block-search-form {
  margin: -2px 0 0;
  float: right; }

#navbar #block-user-login {
  position: relative;
  float: right; }

#block-search-form div {
  display: inline !important;
  float: left; }

#block-search-form .form-item-search-block-form {
  margin: 5px 2px 0 0; }

#block-search-form form {
  margin: 0 15px 0 0; }

#block-user-login form {
  margin: 0; }

#block-user-login .btn {
  margin: 0; }

.admin-menu #block-search-form {
  margin-top: 0; }

#header {
  background: #fff;
  border: none;
  padding: 0; }

#main {
  padding-top: 10px; }

.block-service-links {
  float: right;
  margin: 3px 0 0;
  position: relative; }

#toboggan-login-link {
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 13px; }

#gplus-wrapper {
  display: inline;
  visibility: hidden; }

.block-service-links h2 {
  display: none; }

.block-service-links ul {
  margin: 0;
  padding: 0; }

.block-service-links li {
  display: inline;
  margin: 0;
  padding: 0; }

/* user menu (after login) */
#block-system-user-menu {
  float: right;
  margin: 0;
  clear: both; }

#block-system-user-menu a {
  margin: 0 !important; }

#block-block-6 {
  margin: 0;
  float: right;
  padding: 10px 10px 0; }

.logged-in #navbar #block-service-links-service-links,
.logged-in #navbar #block-service-links-service-links-not-node {
  margin-right: 0; }

/* move social icons to the right after login */
.navbar #block-system-user-menu .nav {
  margin: 0;
  padding: 0; }

/* main nav menu */
ul.nice-menu-down li, .nav-tabs > li {
  margin-bottom: -6px; }

.navbar .nav > li > a {
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-shadow: none; }

#navbar #block-nice-menus-1 .nav {
  background: transparent;
  margin: 20px 0 0 15px; }

.logged-in #navbar #block-nice-menus-1 .nav {
  margin-top: 10px; }

#navbar #block-nice-menus-1 li a.active {
  background-color: #fff !important;
  color: #565656 !important;
  border-radius: 3px 3px 0 0;
  border: none;
  padding-bottom: 12px;
  margin-bottom: 0;
  box-shadow: inset 0 1px 1px #ccc; }

#navbar #block-nice-menus-1 li a:hover {
  background-color: #f79533;
  color: #fff; }

.block-nice-menus .dropdown-menu {
  border-radius: 0;
  border: 1px solid #ddd;
  min-width: 190px; }

ul.nice-menu ul.dropdown-menu li {
  width: auto;
  min-width: 160px; }

ul.nice-menu li, ul.nice-menu-down li.menuparent {
  border: none;
  background: transparent; }

ul.nice-menu-down ul {
  top: 40px; }

.dropdown-menu li > a {
  padding: 7px 20px !important;
  min-width: 160px; }

.navbar .nav li.menuparent > a .caret {
  border-top-color: #777777;
  border-bottom-color: #777777; }

.navbar .nav a .caret {
  margin-top: 8px; }

.nav .dropdown-toggle .caret {
  margin-top: 6px;
  border-top-color: #0088cc;
  border-bottom-color: #0088cc; }

.menuparent .caret {
  margin-top: 8px;
  margin-left: 2px; }

#navbar #block-nice-menus-1 li ul a:hover {
  background-color: #888;
  color: #fff;
  text-shadow: 0 1px 0 #bbb;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

ul.nice-menu-down li.menuparent:hover {
  background: none; }

/* login button */
#toboggan-login-link {
  background-color: #003769;
  background-image: linear-gradient(to bottom, #4f7ab9, #003769);
  background-repeat: repeat-x;
  border-color: #003769 #003769 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25); }

/* login container */
#toboggan-container div.user-login-block {
  text-align: left;
  position: absolute;
  right: -2px;
  z-index: 1000;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #DDD;
  box-shadow: 0 0 9px #ddd;
  margin-top: 2px;
  min-height: 190px; }

#toboggan-login label {
  color: #666; }

#toboggan-login ul {
  margin: 55px 0 0;
  float: right; }

#toboggan-login li {
  list-style: none;
  padding: 0;
  text-align: right; }

#toboggan-login a:hover {
  background-color: transparent !important;
  color: #005580 !important; }

#toboggan-login #edit-actions {
  position: absolute; }

#toboggan-login #edit-actions #edit-submit {
  margin-top: 10px; }

#block-user-login #edit-actions--2 {
  position: absolute; }

div.toboggan-login-link-container {
  width: 140px;
  text-align: right; }

/* slideshow */
#block-views-front-slideshow-block {
  margin: 16px 0 0;
  position: relative;
  z-index: 1; }

#block-views-front-slideshow-block img {
  margin: 0 auto;
  display: block; }

/* front page */
.front #main #content {
  padding: 0; }

.front .node-1173 .links.inline {
  display: none; }

#block-block-8 {
  margin: 15px 2%;
  padding: 2px 15px;
  border: 1px solid #E2F2F8;
  border-radius: 4px;
  background-color: #E2F2F8;
  background-image: -moz-linear-gradient(#F5FBFF, #E2F2F8);
  background-image: -webkit-linear-gradient(#F5FBFF, #E2F2F8);
  background-image: linear-gradient(#F5FBFF, #E2F2F8); }

#block-block-8 h2 {
  color: #666;
  margin: 6px 0 3px;
  font-size: 18px; }

#block-block-8 p {
  margin: 0 0 10px; }

#block-block-8 img {
  float: right;
  height: auto;
  margin-left: 15px;
  margin-top: -15px; }

#block-views-cevent-base-block-2 {
  float: left;
  margin: 15px 2.5%;
  padding: 0 10px;
  width: 93%; }

#block-views-cevent-base-block-2 h2 {
  color: #F79533; }

#twitter-widget-1 {
  min-width: 100px !important; }

#crm-event-block {
  clear: both;
  float: left;
  padding: 20px 0 10px; }

#crm-event-block p {
  float: left;
  width: 167px;
  padding: 0;
  margin: 0 5.25% 15px 0;
  height: 135px; }

.front #block-block-3,
.front #block-views-most-recent-blog-post-block {
  padding: 12px;
  border: 1px solid #dedede;
  border-radius: 4px;
  background-image: -moz-linear-gradient(#eff2fc, #f9f9f9);
  background-image: -webkit-linear-gradient(#eff2fc, #f9f9f9);
  background-image: linear-gradient(#eff2fc, #f9f9f9); }

.front #block-block-3 {
  background-color: #F2F2F2;
  background-image: -moz-linear-gradient(#f2f2f2, #efefef);
  background-image: -webkit-linear-gradient(#f2f2f2, #efefef);
  background-image: linear-gradient(#f2f2f2, #efefef); }

.front #block-block-3 h2,
.front #block-views-most-recent-blog-post-block h2 {
  color: #666;
  font-size: 18px;
  line-height: normal; }

.front #block-views-most-recent-blog-post-block {
  background: #EFF2FC; }

.front #block-block-3 ul {
  padding: 0 5px 20px 5px;
  margin: 0; }

.front #block-block-3 li {
  list-style: none;
  margin: 0 0 0 14px;
  padding: 5px 0;
  text-indent: -17px;
  line-height: normal; }

#block-block-5 {
  margin-top: 20px; }

/* Event displays - sidebars */
#crm-quick-create form {
  margin: 0; }

#crm-quick-create label {
  margin: 8px 0 0 3px; }

#crm-quick-create .form-submit {
  margin-top: 6px; }

/* Login reminder block */
.lower .form-item {
  margin: 10px 0; }

.lower .item-list {
  display: none; }

.lower {
  top: 240px; }

/* My events block */
#block-views-pevents-block {
  clear: both; }

#block-views-pevents-block ul {
  padding: 0;
  margin: 0 0 10px; }

#block-views-pevents-block li {
  color: #666;
  margin: 0 0 10px; }

/* Twitter panel */
#block-block-5 .ext {
  display: none; }

/* CiviCrm merge with Boostrap */
#crm-container h3 {
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 10px;
  padding: 0; }

.page-civicrm input.form-checkbox, .page-civicrm input.form-radio {
  margin: 3px 5px 0 0;
  vertical-align: top; }

#crm-container .label {
  background-color: transparent;
  display: block;
  text-shadow: none;
  white-space: normal;
  line-height: normal;
  font-size: 14px;
  border: none;
  font-weight: bold; }

.crm-container .crm-form-block,
.crm-form-block {
  background-color: #F6F6F6;
  padding: 10px; }

#crm-container tr {
  font-size: 14px; }

#crm-container .crm-error {
  border-radius: 4px 4px 4px 4px;
  margin-bottom: 20px;
  padding: 8px 35px 8px 14px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5); }

div.messages ul {
  margin: 10px 10px 10px 30px; }

#crm-contact-actions-link .icon,
#crm-participant-link .icon,
#crm-event-links-link .icon,
#crm-contribpage-links-link .icon,
#crm-create-new-link .icon,
.button .icon,
.crm-button .icon {
  background-image: url("../images/ui-icons_2e83ff_256x240.png"); }

.ui-wdget {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px; }

#crm-container .panel li a:hover, #crm-container .crm-participant-list-inner li a:hover, #crm-container .crm-event-links-list-inner li a:hover, #crm-container .crm-contribpage-links-list-inner li a:hover {
  background-color: transparent;
  color: #005580;
  text-decoration: none; }

.crm-container .crm-actionlinks-top:after {
  content: "";
  display: table;
  clear: both; }

/* event management */
#mainTabContainer {
  clear: both; }

.page-civicrm-event-manage #mainTabContainer {
  border: none;
  background: none; }

.page-civicrm-event-manage #mainTabContainer ul.ui-widget-header {
  border: none;
  background: none; }

#mainTabContainer .ui-tabs-nav {
  background-color: transparent; }

#mainTabContainer .crm-tab-button a {
  margin: 0; }

#mainTabContainer .nav li a:hover {
  background-color: #888888;
  color: #fff !important; }

#mainTabContainer li.crm-tab-button {
  background-color: #f1f1f1;
  font-size: 13px;
  margin: 0 4px;
  padding: 0;
  background-image: none;
  border: none; }

#mainTabContainer li.ui-state-active {
  background-color: #888; }

#mainTabContainer li.ui-state-active a:link {
  background-color: #888;
  color: #fff !important; }

#crm-container a.button,
#crm-participant-link,
#crm-event-links-link,
#crm-contribpage-links-link,
#crm-contact-actions-link,
#crm-container a.button:link,
#crm-container input.form-submit,
#crm-container input[type="button"],
#crm-container input.form-submit-inline,
#crm-container .crm-button {
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 3px;
  margin-bottom: 5px;
  margin-top: 5px;
  line-height: 20px;
  color: #333;
  text-align: center;
  text-shadow: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: #4F75AD;
  background-image: none;
  border: none;
  box-shadow: none;
  white-space: nowrap;
  position: relative; }

.crm-container a.button, .crm-container a.crm-invoiceButton, .crm-container a.crm-invoiceButton:link, .crm-container a.crm-invoiceButton:visited, .crm-container a.button:link, .crm-container a.button:visited, .crm-container input.crm-form-submit, .crm-container .ui-dialog-buttonset .ui-button, .crm-container input[type=button], .crm-container .crm-button {
  text-shadow: none;
  background: #4F75AD;
  color: #FFF;
  font-size: 13px;
  font-weight: normal;
  margin: 0 6px 0 0;
  padding: 4px 8px;
  text-decoration: none;
  cursor: pointer;
  border: none; }

.crm-container .crm-button-icon {
  top: 1px;
  left: 4px; }

.crm-container .crm-search-form .crm-button-icon {
  top: 7px;
  left: 7px; }

.page-civicrm-event-manage .crm-container .crm-button-icon {
  top: 9px;
  left: 6px; }

.crm-icon-button {
  position: relative; }

#crm-container .crm-icon-button input {
  padding: 5px 10px 5px 23px;
  border: none;
  background-image: none; }

#crm-container .crm-button-type-cancel {
  background-color: #AF5353; }

.page-civicrm-event-manage #actions {
  position: relative; }

.page-civicrm-event-manage #actions .helpicon {
  left: -20px;
  background-image: none;
  font-size: 16px; }

.crm-participant-form-block-role_id label {
  padding-right: 10px; }

#crm-contact-actions-link .dropdown-icon {
  float: left; }

.ac_results {
  background-image: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-clip: padding-box; }

#crm-event-links-wrapper .ac_results li a {
  color: #2786c2; }

#crm-event-links-wrapper .crm-event-links-list-inner,
#crm-event-link-wrapper .crm-participant-list-inner {
  background-color: #fff; }

#crm-event-links-list,
#crm-participant-list {
  top: 32px; }

#crm-participant-link:hover .icon,
#crm-event-links-link:hover .icon {
  background-position: -66px 0;
  margin-top: 4px; }

.page-civicrm-event-manage #crm-event-links-wrapper,
.page-civicrm-event-manage #crm-participant-wrapper {
  padding: 5px 10px;
  background: #eee;
  margin-left: 10px; }

#crm-event-links-wrapper #crm-event-links-link,
#crm-participant-wrapper #crm-participant-link {
  background-color: transparent; }

#crm-participant-list .crm-participant-list-inner {
  background: transparent; }

.crm-container #crm-participant-list .crm-participant-list-inner li,
.crm-container #crm-participant-list .crm-participant-list-inner li a,
.crm-container #crm-participant-list .crm-participant-list-inner li a:visited {
  color: #555; }

.crm-container a.crm-event-feed-link .label {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

/* search and find */
.crm-basic-criteria-form-block .label {
  padding-top: 15px; }

/* CiviCRM events calendar */
.page-events-month.sidebar-second #content {
  width: 77%; }

.page-events-month.sidebar-second .region-sidebar-second {
  width: 23%; }

.page-events .page-header {
  padding-bottom: 0;
  margin-bottom: 10px; }

.view-id-civicrm_events.view-display-id-page .pager {
  margin: 0; }

.view-id-civicrm_events.view-display-id-page .calendar-calendar .view-item-civicrm_events {
  padding: 2px; }

.calendar-calendar .month-view .full td.single-day div.monthview, .calendar-calendar .week-view .full td.single-day div.weekview, .calendar-calendar .day-view .full td.single-day div.dayview {
  background: #fff; }

.calendar-calendar .month-view .full td.single-day .inner div.alert,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div.alert {
  padding: 8px;
  background-color: #178ACC !important;
  border: 1px solid #EFB99E;
  color: #fff;
  text-shadow: none;
  border-radius: 4px; }

.view-id-civicrm_events.view-display-id-page .calendar-calendar .view-item-civicrm_events .contents a {
  color: #fff;
  text-decoration: none;
  font-weight: normal;
  padding: 0; }

.view .date-nav-wrapper .date-nav {
  margin: 0; }

.view .date-nav-wrapper .date-prev, .view .date-nav-wrapper .date-next {
  background-color: transparent;
  border-radius: 0; }

.view .date-nav-wrapper .date-prev {
  right: auto;
  left: 0; }

.calendar-calendar div.calendar {
  font-size: .95em; }

.calendar-calendar .month-view .full tr td.today {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background: #ffffcc; }

.calendar-calendar .month-view .full td.date-box.today {
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #ccc; }

.calendar-calendar .month-view .full tr td.single-day.today {
  border-bottom: 1px solid #ccc; }

#block-views-civicrm-events-block-2 {
  margin: 60px 0 0 10px; }

.upcoming-date-wrapper {
  text-align: right;
  display: inline-block;
  border-left: 4px solid #EEE;
  padding-left: 5px;
  float: left;
  height: 41px;
  width: 50px;
  font-size: 16px;
  margin-right: 10px; }

.upcoming-date-title {
  margin: 0 0 10px 90px; }

.view-display-id-block_2 ul {
  margin: 0;
  padding: 0; }

.calendar-calendar .week-view .full div.single-day div.weekview, .calendar-calendar .day-view .full div.single-day div.dayview {
  border: 1px solid #DDD; }

/* training calendar event labels */
.views-field-event-type .label {
  width: 84px; }

.views-field-event-type .Online,
.calendar-calendar .month-view .full td.single-day .inner div.Online,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div.Online {
  background-color: #178ACC !important;
  font-weight: normal;
  color: #fff;
  text-shadow: none;
  padding: 10px; }

.view-header .Online {
  background-color: #178ACC; }

.views-field-event-type .Webinar,
.calendar-calendar .month-view .full td.single-day .inner div.Webinar,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div.Webinar {
  background-color: #AF82D8 !important;
  color: #fff !important;
  font-weight: normal;
  text-shadow: none;
  padding: 10px; }

.view-header .Webinar {
  background-color: #AF82D8; }

.calendar-calendar .month-view .full td.single-day .inner div.On-Ground,
.views-field-event-type .On-Ground,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div.On-Ground {
  background-color: #F183AF !important;
  /*ee6e7d*/
  color: #fff !important;
  font-weight: normal;
  text-shadow: none;
  padding: 10px; }

.view-header .On-Ground {
  background-color: #F183AF; }

.views-field-event-type .Online,
.views-field-event-type .Webinar,
.views-field-event-type .On-Ground {
  padding: 5px; }

/* CiviCRM individual event display */
#crm-create-new-wrapper {
  margin: 10px 0; }

.page-civicrm-event .page-header {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none; }

.page-civicrm-event .page-header #page-title {
  margin-bottom: 0;
  padding-right: 50px; }

.archive-link {
  margin: 0 0 10px 10px; }

.crm-container .archive-link a {
  font-weight: bold;
  color: #fff; }

.subtitle-wrapper {
  margin: 0 10px 15px; }

.subtitle-wrapper h2 {
  margin-top: 0; }

.page-civicrm-event-info .messages.status {
  display: none;
  max-width: 585px; }

.crm-accordion-wrapper {
  min-height: 20px;
  padding: 0px;
  margin-bottom: 20px;
  background-color: whiteSmoke;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }

#block-block-7 h2 {
  line-height: normal; }

.event_description-section, .event_summary-section {
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none; }

.crm-section.event_description-section img {
  max-width: none; }

#event-specifics-wrapper {
  max-width: 750px;
  clear: both; }

#crm-container fieldset {
  margin: 10px 6px; }

.event-instructor {
  padding: 10px 0 20px; }

#crm-container .event-info-detail th {
  background-color: transparent;
  color: #555;
  font-size: 14px;
  border: none; }

#crm-container .event-info-detail td {
  border: none; }

#crm-event-info-wrapper {
  padding-bottom: 40px;
  margin: 0 10px;
  clear: both; }

#crm-event-info-wrapper .row {
  margin-left: 0; }

#Event_Addons__25_wrapper,
#Event_Attribs__Admin__23_wrapper {
  display: none; }

.editor #Event_Attribs__Admin__23_wrapper {
  display: block; }

#crm-container .register_link-top {
  float: left;
  margin: 0 0 15px 0; }

#crm-container .crm-actions-ribbon {
  float: right; }

.crm-event-manage-tab-actions-ribbon {
  font-size: 15px; }

.page-civicrm-event-manage #crm-container .crm-event-manage-tab-actions-ribbon #actions {
  float: right;
  margin-top: 10px; }

#crm-container .crm-event-manage-tab-actions-ribbon .dropdown-menu {
  top: 29px; }

#crm-container .crm-event-manage-tab-actions-ribbon .dropdown-menu div,
#crm-container .crm-event-manage-tab-actions-ribbon #actions ul {
  background-color: #fff !important; }

#crm-container .crm-event-manage-tab-actions-ribbon #actions ul {
  padding: 3px 10px; }

#crm-container .crm-actions-ribbon li {
  margin: 0 !important; }

#crm-container .crm-actions-ribbon .ac_results li {
  float: none;
  white-space: normal;
  font-size: 13px; }

#crm-container .crm-actions-ribbon .ac_results li > a {
  padding: 0 !important;
  min-width: 160px; }

.crm-event-manage-tab-actions-ribbon span.crm-hover-button {
  appearance: none;
  display: inline-block;
  padding: 4px 12px;
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-repeat: repeat-x;
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  border: 1px solid #cccccc;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #b3b3b3; }
  .crm-event-manage-tab-actions-ribbon span.crm-hover-button:hover, .crm-event-manage-tab-actions-ribbon span.crm-hover-button:focus {
    text-decoration: none;
    transition: background-position 0.1s linear; }
  .crm-event-manage-tab-actions-ribbon span.crm-hover-button:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .crm-event-manage-tab-actions-ribbon span.crm-hover-button.active, .crm-event-manage-tab-actions-ribbon span.crm-hover-button:active {
    background-image: none;
    outline: 0;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
  .crm-event-manage-tab-actions-ribbon span.crm-hover-button:hover, .crm-event-manage-tab-actions-ribbon span.crm-hover-button:focus, .crm-event-manage-tab-actions-ribbon span.crm-hover-button:active, .crm-event-manage-tab-actions-ribbon span.crm-hover-button.active, .crm-event-manage-tab-actions-ribbon span.crm-hover-button.disabled, .crm-event-manage-tab-actions-ribbon span.crm-hover-button[disabled] {
    color: #333333;
    background-color: #e6e6e6; }
  .crm-event-manage-tab-actions-ribbon span.crm-hover-button:active, .crm-event-manage-tab-actions-ribbon span.crm-hover-button.active {
    background-color: #cccccc; }
  .crm-event-manage-tab-actions-ribbon span.crm-hover-button:hover, .crm-event-manage-tab-actions-ribbon span.crm-hover-button:focus {
    color: #333333; }
  .crm-event-manage-tab-actions-ribbon span.crm-hover-button .crm-i {
    color: #2786C2; }

.crm-event-manage-tab-actions-ribbon .icon {
  cursor: pointer; }

.crm-register-button {
  color: #fff !important; }

.crm-section {
  padding: 0; }

.crm-event-manage-registration-form-block-confirm_email_text {
  vertical-align: top; }

#confirm_email_text {
  width: 99%;
  min-height: 400px; }

#crm-container div#printer-friendly {
  margin: -55px 5px 0 0;
  font-size: 24px; }

#crm-container .crm-event-info-form-block table.no-border .section-shown {
  padding: 0; }

#crm-container .crm-section div.content {
  padding: 10px;
  margin-left: 0; }

.crm-accordion-header {
  padding: 9px; }

.crm-accordion-open > .crm-accordion-body {
  border: none;
  background: #f4f4f4;
  padding: 15px 15px 5px; }

#crm-container .crm-info-panel {
  margin: 0 0 10px;
  border: none; }

#crm-container .crm-accordion-body .crm-info-panel {
  margin: 0; }

#crm-container .crm-accordion-body .crm-info-panel td.html-adjust {
  color: #555;
  border: none;
  width: 100%;
  background: transparent; }

#crm-container td.price_set_option-label {
  padding-left: 0; }

.price_set_option-label {
  padding: 0; }

.event-info table {
  max-width: 660px; }

#crm-container #Confirm .crm-submit-buttons,
#crm-container #Confirm .register_link-bottom,
#crm-container #Register .crm-submit-buttons,
#crm-container #Register .register_link-bottom {
  float: left;
  margin-top: 10px; }

#crm-container #Register .crm-submit-buttons {
  float: none;
  margin: 20px 0 30px 10px;
  height: auto; }

#crm-container #EventInfo .crm-submit-buttons {
  height: auto;
  margin: 11px;
  float: left;
  width: 100%; }

.event_footer_text-section p {
  margin: 10px !important; }

#crm-container .register_link-bottom {
  margin-top: 24px; }

#crm-container .event_date_time-section,
#crm-container .event_fees-section {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  max-width: 630px;
  background: #f4f4f4;
  border: 1px solid #cdcdcd;
  border-radius: 3px; }
  #crm-container .event_date_time-section .content,
  #crm-container .event_fees-section .content {
    width: 100%; }
  #crm-container .event_date_time-section.crm-section,
  #crm-container .event_fees-section.crm-section {
    margin-bottom: 10px; }
  #crm-container .event_date_time-section > .label,
  #crm-container .event_fees-section > .label {
    text-align: left;
    margin-left: 2.564102564102564%;
    margin-right: 2.564102564102564%;
    width: 23.076923076923077%;
    padding: 8px 15px;
    background-color: #8faa6B;
    border-radius: 3px 0 0 3px; }
    #crm-container .event_date_time-section > .label:first-child,
    #crm-container .event_fees-section > .label:first-child {
      margin-left: 0; }
    #crm-container .event_date_time-section > .label:last-child,
    #crm-container .event_fees-section > .label:last-child {
      margin-right: 0; }

.event_date_time-section .iCal_links-section {
  float: right; }

.event_fees-section .fee_block-table.form-layout-compressed {
  width: 100%; }

.event_fees-section .fee_block-table .crm-event-label {
  margin-left: 2.564102564102564%;
  margin-right: 2.564102564102564%;
  width: 23.076923076923077%;
  padding-left: 0;
  padding-right: 0;
  font-weight: bold; }
  .event_fees-section .fee_block-table .crm-event-label:first-child {
    margin-left: 0; }
  .event_fees-section .fee_block-table .crm-event-label:last-child {
    margin-right: 0; }

.event_fees-section .fee_block-table .fee_amount-value {
  margin-left: 2.564102564102564%;
  margin-right: 2.564102564102564%;
  width: 100%;
  padding-left: 11px; }
  .event_fees-section .fee_block-table .fee_amount-value:first-child {
    margin-left: 0; }
  .event_fees-section .fee_block-table .fee_amount-value:last-child {
    margin-right: 0; }

#crm-container .event_description-section {
  max-width: 630px; }

#crm-container .iCal_links-section {
  font-size: 24px; }

#ThankYou .iCal_links-section {
  margin: 5px 0; }

#crm-container .ical-links-top .iCal_links-section {
  margin: 0; }

.iCal_links-section a {
  float: left; }

#crm-container .iCal_links-section .ical-link-button {
  margin-top: 5px;
  color: #fff !important; }

/* CiviCRM event social links */
#crm-container .help {
  background-color: #c4E1F4; }

#crm-container .crm-socialnetwork {
  width: 375px;
  border: none;
  padding: 0 15px 15px;
  margin: 30px 0 0;
  clear: both;
  color: #666; }

#crm-container .crm-socialnetwork h3 {
  padding: 10px 0 6px 2px;
  margin: 0;
  color: #13996a;
  /*e6661d*/
  font-weight: normal; }

#crm-container .crm-socialnetwork .description {
  display: none; }

#crm-container .crm-socialnetwork .label {
  display: block;
  background: transparent;
  float: left;
  width: 100%;
  min-height: 25px;
  text-align: left; }

#crm-container .crm-socialnetwork iframe {
  height: 25px !important; }

/* event admin links and blocks */
#block-civicrm-1 {
  clear: both; }

#id_fulltext_search {
  margin: 0; }

#id_fulltext_search div {
  margin: 0 !important; }

#id_fulltext_search #text {
  width: 115px; }

#fulltext_submit {
  margin-top: -11px; }

#fulltext_table {
  margin-top: -5px; }

/* event registration */
#crm-container .register_link-section a.button {
  appearance: none;
  display: inline-block;
  padding: 4px 12px;
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-repeat: repeat-x;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25); }
  #crm-container .register_link-section a.button:hover, #crm-container .register_link-section a.button:focus {
    text-decoration: none;
    transition: background-position 0.1s linear; }
  #crm-container .register_link-section a.button:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  #crm-container .register_link-section a.button.active, #crm-container .register_link-section a.button:active {
    background-image: none;
    outline: 0;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
  #crm-container .register_link-section a.button:hover, #crm-container .register_link-section a.button:focus, #crm-container .register_link-section a.button:active, #crm-container .register_link-section a.button.active, #crm-container .register_link-section a.button.disabled, #crm-container .register_link-section a.button[disabled] {
    color: #ffffff;
    background-color: #2f96b4; }
  #crm-container .register_link-section a.button:active, #crm-container .register_link-section a.button.active {
    background-color: #24748c; }
  #crm-container .register_link-section a.button.active,
  #crm-container .register_link-section a.button .btn-inverse.active {
    color: rgba(255, 255, 255, 0.75); }

#crm-container .crm-section.summary {
  padding: 0; }

#crm-container #Register {
  background-color: #fafafa;
  clear: both;
  float: left;
  margin: 0 0 20px;
  width: 100%; }

#crm-container #Register .crm-section {
  margin: 0; }

#crm-container #Register .label,
#crm-container #Register .field-label {
  color: #333;
  margin: 0; }

#crm-container #Register .field-label label {
  display: inline-block;
  padding: 5px 0 0 3px; }

#crm-container #Register .crm-section div.content {
  padding: 1px; }

#crm-container #Register .crm-section .form-radio {
  margin: 0 5px 0 0; }

#crm-container #Register .form-inline {
  margin-left: 5px; }

#crm-container #Register .form-inline label {
  margin: 0 5px 0 0; }

.page-civicrm-event-register .payment_processor-section {
  /* "Payment Method" label*/
  /* Payment Method radio buttons*/ }
  .page-civicrm-event-register .payment_processor-section > .label {
    float: none; }
    .page-civicrm-event-register .payment_processor-section > .label label {
      display: block;
      text-align: left; }
  .page-civicrm-event-register .payment_processor-section .content input[type="radio"] {
    margin-top: 0;
    margin-right: 3px; }
  .page-civicrm-event-register .payment_processor-section .content label {
    margin-right: 10px; }
  .page-civicrm-event-register .payment_processor-section .content .crm-error {
    display: block;
    margin-top: 5px; }

#noOfparticipants {
  margin: 0 !important; }

#noOfparticipants .content {
  margin: 0 0 0 4px !important;
  float: left;
  width: 70%; }

#noOfparticipants .content select {
  margin-bottom: -7px; }

#noOfparticipants .description {
  display: block;
  max-width: 500px;
  float: left;
  margin: -3px 0 0 66px; }

.priceset-help {
  padding: 0 0 15px; }

#crm-container span.crm-button_qf_Main_upload input,
#crm-container span.crm-button_qf_Confirm_next input,
#crm-container span.crm-button_qf_Register_upload input {
  font-size: 14px; }

/* registration confirmation page */
#Confirm .row {
  margin-left: 0; }

#crm-container #Confirm .crm-submit-buttons {
  float: none;
  margin: 20px 0; }

#crm-container #Confirm div.display-block {
  margin: 0; }

#crm-container .crm-button-type-cancel,
#crm-container .crm-button-type-back {
  margin-left: 0; }

#crm-container #Confirm .crm-section {
  margin: 0; }

#crm-container #Confirm .crm-section .field-label,
#crm-container #Confirm .crm-section .content {
  padding: 0; }

#crm-container #Confirm .crm-section .field-label label {
  color: #666;
  font-weight: bold; }

/* registration thank you page */
#crm-container #ThankYou .event-info-wrapper .event-info-detail {
  min-height: 20px;
  margin-left: 2.564102564102564%; }

#crm-container #ThankYou .crm-section .field-label,
#crm-container #ThankYou .crm-section .content {
  padding: 0; }

/* scholarship form */
.crm-contribution-page-id-1 #priceset .contribution_amount-section .field-label.span3 {
  display: none; }

.crm-contribution-page-id-1 #priceset .other_amount-section .field-label {
  float: left;
  margin-right: 10px;
  display: none; }

#crm-container .crm-contribution-page-id-1 .crm-section .other_amount-content {
  margin-left: 23px; }

#crm-container .crm-contribution-page-id-1 .crm-section div.content {
  padding: 0; }

#crm-container .crm-contribution-page-id-1 .email-5-section {
  clear: both;
  margin-top: 15px;
  float: left;
  width: 100%;
  margin-left: 2px;
  margin-bottom: 15px; }

#crm-container .crm-contribution-page-id-1 .email-5-section .label {
  float: none; }

#crm-container .crm-contribution-page-id-1 .email-5-section label {
  font-size: 17px;
  font-weight: bold;
  line-height: 32px; }

#crm-container .crm-contribution-page-id-1 .crm-section .label {
  color: #333;
  text-align: left;
  padding-left: 0; }

#crm-container .crm-contribution-page-id-1 .payment_processor-section .label {
  padding-top: 0; }

#crm-container .crm-contribution-page-id-1 .payment_processor-section .content label {
  padding-right: 19px; }

.custom_pre_profile-group .span3 {
  width: 17%; }

#crm-container .crm-contribution-page-id-1 #credit_card_type {
  width: 125px; }

#crm-container .crm-contribution-page-id-1 #credit_card_number {
  width: 215px; }

#crm-container .crm-contribution-page-id-1 #cvv2 {
  width: 50px; }

#crm-container .crm-contribution-page-id-1 #credit_card_exp_date_M {
  width: 90px; }

#crm-container .crm-contribution-page-id-1 #credit_card_exp_date_Y {
  width: 100px; }

.crm-contribution-page-id-1 #billingcheckbox {
  margin: 0 5px 0 12px; }

#crm-container .crm-contribution-page-id-1 #crm-submit-buttons input#_qf_Main_upload-bottom {
  font-size: 15px;
  padding: 4px 12px; }

/* training list */
.page-views .feed-icon {
  margin-right: 30px;
  float: right; }

.view-id-cevent_base .item-list ul {
  margin: 5px 0 20px;
  padding: 0;
  float: left;
  clear: both;
  width: 100%;
  max-width: 760px; }

.view-empty {
  margin: 20px 30px; }

.not-front .view-id-cevent_base .item-list ul {
  min-width: 660px; }

.not-front .sidebar .view-id-cevent_base .item-list ul {
  min-width: 150px; }

.view-id-cevent_base li {
  float: left;
  width: 98%;
  clear: both;
  padding: 8px !important;
  margin: 0 !important;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd; }

.view-id-cevent_base .pagination li {
  float: none;
  width: auto;
  clear: none;
  border: none; }

.view-id-cevent_base .views-row-odd {
  background-color: #f9f9f9; }

.view-id-cevent_base .views-field-title {
  float: left;
  margin: 0 10px 0 0;
  font-weight: bold;
  max-width: 81%; }

.view-id-cevent_base .views-field-event-type {
  float: right; }

.view-id-cevent_base .views-field-start-date,
.view-id-cevent_base .views-field-friendly-date-6 {
  clear: both;
  color: #575757; }

.view-id-cevent_base .views-field-friendly-date-6 {
  float: left;
  clear: left; }

.view-id-cevent_base .views-field-summary p {
  margin: 0; }

#block-menu-menu-training-menu ul {
  padding: 10px 0 0; }

/* book navigation */
.book-nav-horizontal .well {
  margin: 10px 30px 40px;
  float: left; }

.book-nav-horizontal .nav {
  margin: 0;
  padding: 0; }

.book-nav-horizontal .pager-wrapper {
  margin: 45px 0 0 25px;
  clear: both; }

#block-book-navigation h2 {
  line-height: 30px;
  margin-left: -5px;
  margin-bottom: 5px; }

#block-book-navigation ul.nav li {
  padding: 0;
  text-indent: -20px; }

#block-book-navigation li [class^="icon-"], #block-book-navigation .nav li [class^="icon-"],
#block-book-navigation li [class*=" icon-"], #block-book-navigation .nav li [class*=" icon-"] {
  width: 1.75em; }

#block-book-navigation .nav {
  margin: 0 0 10px 17px;
  padding: 0; }

#block-book-navigation .nav .nav {
  margin: 5px 0 10px 18px; }

#block-book-navigation li {
  list-style: none !important; }

#block-book-navigation li a.active {
  color: #474747;
  display: inline; }

#block-book-navigation .nav > li > a {
  display: inline; }

.node-type-book .pager {
  padding: 0; }

#book-internal-nav a {
  max-width: 175px;
  overflow: hidden; }

#block-book-navigation .icon-file {
  color: #999;
  padding-right: .28em !important; }

.node-book .links.inline {
  margin: 20px 0; }

/* book content */
#content .node-book .field-name-body {
  min-height: 300px; }

.node-type-book #content h1, .node-type-book #content .region-content {
  margin-left: 10px; }

/* blogs */
.tagclouds-term {
  float: left;
  margin: 0 10px 15px 0; }

#block-tagclouds-2 .more-link {
  clear: both; }

.node .submitted, article .submitted {
  margin: 0 10px 20px 20px; }

article.node-teaser {
  max-width: 700px; }

.section-blog .node-teaser .submitted {
  width: 150px; }

.blog-teaser p {
  margin: 12px 0 0; }

/* blogs theming */
.field-name-field-blog-tags, .field-name-field-blog-categories {
  float: left;
  width: 100%;
  margin: 10px 0 20px; }

.field-name-field-blog-tags .field-label, .field-name-field-blog-categories .field-label {
  font-weight: normal;
  font-size: 17px;
  margin: 8px 0; }

.field-name-field-blog-tags .field-item a, .field-name-field-blog-categories .field-item a {
  display: block;
  float: left;
  margin: 2px 5px 5px 0;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #2D6987; }

/* admin view */
#block-system-user-menu h2 {
  display: none; }

.page-civicrm-admin .container {
  width: 96.6%; }

/* sidebar */
.well ul {
  margin: 0 0 10px; }

#sidebar_first .nav, #sidebar_second .nav {
  padding: 0 0 0 5px; }

#sidebar_first .block {
  clear: both; }

#sidebar_first li {
  margin: 0 0 8px; }

/* Account registration */
form#Register select {
  width: auto; }

#user-register-form .crm-section .content {
  margin: 0; }

#profilewrap14 fieldset {
  margin: 0;
  padding: 0; }

#profilewrap14 legend {
  display: none; }

#profilewrap14 .messages p {
  margin: 0; }

#user-register-form .form-item {
  margin: 12px 0 0;
  position: relative; }

#edit-field-user-biography {
  margin-top: 50px; }

#user-register-form .form-item-name .description,
#user-register-form .form-item-mail .description {
  position: absolute;
  left: 300px;
  top: 5px;
  width: 65%; }

#user-register-form #field-user-biography-add-more-wrapper {
  max-width: 660px; }

#user-register-form #edit-timezone {
  margin: 25px 0 0;
  padding: 0; }

#user-register-form #edit-timezone legend a, #user-register-form #edit-timezone legend a:hover {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none; }

#user-register-form #edit-timezone legend span {
  padding: 0; }

#user-register-form #edit-timezone .fieldset-wrapper {
  padding: 0; }

#user-register-form #edit-timezone .description {
  top: 63px; }

#user-register-form .form-actions {
  padding: 0;
  margin-top: 46px; }

.mollom-privacy {
  margin: 10px 0; }

#user-register-form .captcha {
  margin-top: 20px;
  max-width: 450px;
  background: #f1f1f1; }

#user-register-form .captcha legend {
  background-color: #c8ecc8;
  padding: 15px 20px 0;
  max-width: 410px; }

#user-register-form .captcha .fieldset-wrapper {
  padding: 10px 20px 15px;
  max-width: 410px; }

#user-register-form .captcha img {
  margin: 10px 0; }

/* primary tabs for page options: i.e. edit, view, revisions, etc. */
#page-options {
  position: relative;
  z-index: 100; }

#page-tabs {
  padding: 0; }

#page-tabs li {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none; }

.btn-group > li:first-child .btn {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px; }

.btn-group > li .btn {
  margin-left: -1px;
  margin-right: 0; }

.btn-group > li .btn {
  font-size: 14px; }

.btn-group > li .btn {
  position: relative;
  border-radius: 0; }

.btn-group > li:last-child .btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

/* footer */
#footer {
  margin-top: 35px; }

#footer .container {
  border-top: 1px solid #ddd;
  padding: 20px 0 0; }

#block-nice-menus-2 {
  margin: 0; }

#footer p {
  margin: 0;
  padding: 0.3em 0; }

@media (min-width: 1200px) {
  .container,
  .navbar-static-top .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 1170px; }
  .front #main #content {
    padding: 0 .3%; } }

@media (max-width: 1174px) {
  #logo-wrapper {
    width: 30%;
    float: left; }
  .region-masthead {
    width: 68% !important; }
  #tagline {
    margin: 5px; }
  #logo img {
    height: 48px; }
  #logo {
    margin: 10px 5px;
    clear: both; } }

@media (max-width: 979px) and (min-width: 768px) {
  .btn-navbar {
    display: none; }
  .navbar .nav-collapse {
    clear: none; }
  .nav-collapse {
    height: auto;
    overflow: auto; }
  .navbar .nav {
    float: left;
    margin: 0 10px 0 0; }
  .navbar .brand {
    margin-left: -20px;
    padding: 8px 20px 12px; }
  .navbar .dropdown-menu:before, .navbar .dropdown-menu:after {
    display: block; }
  .navbar .nav > li > a, .navbar .dropdown-menu a {
    border-radius: 0;
    color: #999999;
    font-weight: normal;
    padding: 10px 8px 8px; }
  .navbar .nav > li {
    float: left; }
  .navbar .dropdown-menu {
    background-clip: padding-box;
    background-color: #FFFFFF;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5px 5px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    display: none;
    float: left;
    left: 0;
    list-style: none outside none;
    margin: 0;
    min-width: 160px;
    padding: 4px 0;
    position: absolute;
    top: 100%;
    z-index: 1000; }
  .navbar-form, .navbar-search {
    border: none;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1) inset, 0 1px 0 rgba(255, 255, 255, 0.1);
    float: left;
    margin-bottom: 0;
    margin-top: 6px;
    padding: 9px 15px; }
  .navbar .nav.pull-right {
    float: right;
    margin-left: auto; }
  .nav-collapse, .nav-collapse.collapse {
    overflow: visible;
    height: auto; }
  .navbar .btn-navbar {
    display: none; }
  #social-csusm-wrapper {
    width: auto; }
  #social-csusm-wrapper #get-social {
    margin: 0;
    width: 100px;
    float: right; }
  #social-csusm-wrapper #csusm-menu {
    margin: 0;
    width: 90px;
    float: right; }
  #main-menu {
    margin-left: 0; }
  .container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
    width: auto; } }

@media (max-width: 870px) and (min-width: 768px) {
  #block-search-form {
    clear: both;
    margin-top: 10px;
    margin-right: -26px; } }

@media (max-width: 767px) {
  body {
    font-size: 13px; }
  .view-id-cevent_base .item-list ul {
    min-width: 200px; }
  #navbar #block-user-login {
    margin-bottom: 0; }
  #navbar #block-nice-menus-1 .nav {
    margin: 0; }
  .region-masthead {
    margin: 5px;
    float: none; }
  .region-navigation {
    float: none; }
  #block-search-form {
    float: left; }
  #edit-search-block-form--2 {
    width: auto;
    min-width: 145px; }
  .region-masthead .block-service-links {
    display: none; }
  #navbar .navbar-inner {
    padding: 0;
    height: 44px; }
  #navbar #site-nav {
    position: absolute;
    background: #fff;
    width: 100%;
    z-index: 100;
    margin-top: 54px;
    background-color: #f2f2f2; }
  ul.nice-menu li.menuparent ul, #header-region ul.nice-menu li.menuparent ul {
    display: block !important;
    visibility: visible !important;
    border: none; }
  ul.nice-menu ul.dropdown-menu li {
    float: none; }
  ul.nice-menu-down .menuparent a {
    color: #555; }
  #block-search-form form {
    margin: 0; }
  .region-masthead {
    width: 100% !important;
    clear: both;
    float: right !important; }
  #logo-wrapper {
    width: 157px;
    margin: 0 0 0 0; }
  .row-fluid .region-masthead {
    margin: 0;
    padding: 0;
    min-height: 0; }
  #logo img {
    height: 45px; }
  #tagline {
    margin-top: 0;
    margin-left: 8px;
    width: 200px; }
  #block-search-form {
    position: absolute;
    top: 1px;
    right: 6px; }
  .not-logged-in #block-search-form {
    right: 44px; }
  #toboggan-container {
    text-align: left; }
  div.toboggan-login-link-container {
    width: auto;
    text-align: left; }
  #block-user-login .btn {
    padding: 2px 4px 0;
    font-size: 12px;
    text-align: center;
    height: 32px;
    overflow: hidden; }
  #block-user-login .btn .icon-user {
    padding: 4px !important;
    font-size: 22px;
    color: #efefef; }
  #mobile-site-nav {
    position: absolute;
    top: 4px;
    left: auto;
    right: 53px;
    margin: 0;
    padding: 8px 7px;
    height: 18px;
    overflow: hidden; }
  .not-logged-in #mobile-site-nav {
    right: 104px; }
  #mobile-site-search {
    padding: 6px 8px 4px;
    float: right; }
  #mobile-site-search .icon-search {
    font-size: 20px;
    padding: 6px 1px !important;
    height: 25px;
    overflow: hidden; }
  #search-collapse {
    background-color: #FFF;
    top: 4px;
    z-index: 100; }
  #search-collapse .form-item {
    display: block;
    padding: 20px 0 20px 10px;
    background: #f2f2f2;
    margin: 0; }
  #search-collapse .form-wrapper {
    display: block;
    padding: 20px 20px 20px 0;
    background: #f2f2f2; }
  #search-collapse .form-submit {
    margin: 0; }
  #toboggan-login-link, #mobile-site-nav {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #49afcd;
    background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
    background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
    background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
    background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f96b4', GradientType=0);
    border-color: #2f96b4 #2f96b4 #1f6377;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false); }
  #toboggan-login-link:hover,
  #mobile-site-nav:hover {
    color: #FFF;
    background-color: #2F96B4; }
  #navbar #block-user-login {
    position: absolute;
    top: 4px;
    right: 6px;
    z-index: 100; }
  .navbar .btn-navbar .icon-bar {
    display: block;
    width: 8px;
    height: 1px;
    background-color: #efefef !important; }
  #block-views-cevent-base-block-2 {
    margin: 0;
    padding: 0;
    width: 100%; }
  #block-views-front-slideshow-block {
    margin: 0; } }
