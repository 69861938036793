/* CiviCRM individual event display */
#crm-create-new-wrapper{
  margin: 10px 0;
}
.page-civicrm-event .page-header{
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}
.page-civicrm-event .page-header #page-title{
  margin-bottom: 0;
  padding-right: 50px;
}
.archive-link{
  margin: 0 0 10px 10px;
}
.crm-container .archive-link a{
  font-weight: bold;
  color: #fff;
}
.subtitle-wrapper{
  margin: 0 10px 15px;
}
.subtitle-wrapper h2{
  margin-top: 0;
}
.page-civicrm-event-info .messages.status{
  display: none;
  max-width:585px;
}
.crm-accordion-wrapper{
  min-height: 20px;
  padding: 0px;
  margin-bottom: 20px;
  background-color: whiteSmoke;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
#block-block-7 h2{
  line-height: normal;
}
.event_description-section,.event_summary-section{
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.crm-section.event_description-section img{
  max-width: none;
}
#event-specifics-wrapper{
  max-width: 750px;
  clear: both;
}
#crm-container fieldset {
  margin: 10px 6px;
}
.event-instructor{
  padding: 10px 0 20px;
}
#crm-container .event-info-detail th{
  background-color: transparent;
  color:#555;
  font-size: 14px;
  border:none;
}
#crm-container .event-info-detail td{
  border:none;
}
#crm-event-info-wrapper{
  padding-bottom: 40px;
  margin: 0 10px;
  clear: both;
}
#crm-event-info-wrapper .row{
  margin-left: 0;
}
#Event_Addons__25_wrapper,
#Event_Attribs__Admin__23_wrapper{
  display: none;
}
.editor #Event_Attribs__Admin__23_wrapper{
  display: block;
}
#crm-container .register_link-top {
  float: left;
  margin: 0 0 15px 0;
}
#crm-container .crm-actions-ribbon {
  float: right;
}
.crm-event-manage-tab-actions-ribbon{
  font-size: 15px;
}
.page-civicrm-event-manage #crm-container .crm-event-manage-tab-actions-ribbon #actions{
  float: right;
  margin-top: 10px;
}
#crm-container .crm-event-manage-tab-actions-ribbon .dropdown-menu {
  top:29px;
}
#crm-container .crm-event-manage-tab-actions-ribbon .dropdown-menu div,
#crm-container .crm-event-manage-tab-actions-ribbon #actions ul{
  background-color: #fff !important;
}
#crm-container .crm-event-manage-tab-actions-ribbon #actions ul{
  padding: 3px 10px;
}
#crm-container .crm-actions-ribbon li {
  margin: 0 !important;
}
#crm-container .crm-actions-ribbon .ac_results li {
  float: none;
  white-space: normal;
  font-size: 13px;
}
#crm-container .crm-actions-ribbon .ac_results li > a{
  padding: 0 !important;
  min-width: 160px;
}
.crm-event-manage-tab-actions-ribbon span.crm-hover-button {
  @include bootstrap-button();

  .crm-i {
    color: #2786C2;
  }
}
.crm-event-manage-tab-actions-ribbon .icon{
  cursor: pointer;
}
.crm-register-button{
  color:#fff !important;
}
.crm-section{
  padding:0;
}
.crm-event-manage-registration-form-block-confirm_email_text{
  vertical-align: top;
}
#confirm_email_text{
  width:99%;
  min-height: 400px;
}
#crm-container div#printer-friendly {
  margin:-55px 5px 0 0;
  font-size: 24px;
}
#crm-container .crm-event-info-form-block table.no-border .section-shown{
  padding: 0
}
#crm-container .crm-section div.content{
  padding:10px;
  margin-left: 0
}
.crm-accordion-header {
  padding: 9px;
}
.crm-accordion-open>.crm-accordion-body{
  border: none;
  background: #f4f4f4;
  padding:15px 15px 5px;
}
#crm-container .crm-info-panel{
  margin:0 0 10px;
  border:none;
}
#crm-container .crm-accordion-body .crm-info-panel{
  margin: 0;
}
#crm-container .crm-accordion-body .crm-info-panel td.html-adjust{
  color:#555;
  border:none;
  width: 100%;
  background: transparent;}
#crm-container td.price_set_option-label {
  padding-left: 0;
}
.price_set_option-label{
  padding:0;
}
.event-info table{
  max-width:660px;
}
#crm-container #Confirm .crm-submit-buttons,
#crm-container #Confirm .register_link-bottom,
#crm-container #Register .crm-submit-buttons,
#crm-container #Register .register_link-bottom{
  float: left;
  margin-top: 10px;
}
#crm-container #Register .crm-submit-buttons{
  float: none;
  margin: 20px 0 30px 10px;
  height: auto;
}
#crm-container #EventInfo .crm-submit-buttons {
  height: auto;
  margin: 11px;
  float: left;
  width: 100%;
}
.event_footer_text-section p{
  margin: 10px !important;
}

#crm-container .register_link-bottom{
  margin-top: 24px;
}

#crm-container .event_date_time-section,
#crm-container .event_fees-section {
  @include row();
  position: relative;
  max-width: 630px;
  background: $color-off-white;
  border: 1px solid $color-gray-light;
  border-radius: 3px;
  .content {
    width: 100%;
  }

  &.crm-section {
    margin-bottom: 10px;
  }

  > .label {
    text-align: left;
    @include span3();
    padding: 8px 15px;
    background-color: $color-green;
    border-radius: 3px 0 0 3px;
  }
}

.event_date_time-section {
  .iCal_links-section {
    float: right;
  } 
}
.event_fees-section {
  .fee_block-table {
    &.form-layout-compressed {
      width: 100%;
    }
    .crm-event-label {
      @include span3();
      padding-left: 0;
      padding-right: 0;
      font-weight: bold;
    }
    .fee_amount-value {
      @include span12();
      padding-left: 11px;
    }
  }
}
#crm-container .event_description-section {
  max-width: 630px;
}

#crm-container .iCal_links-section{
  font-size: 24px;
}
#ThankYou .iCal_links-section {
  margin:5px 0;
}
#crm-container .ical-links-top .iCal_links-section{
  margin:0;
}
.iCal_links-section a{
  float: left;
}
#crm-container .iCal_links-section .ical-link-button{
  margin-top:5px;
  color: #fff !important;
}


/* CiviCRM event social links */
#crm-container .help {
  background-color:#c4E1F4;
}
#crm-container .crm-socialnetwork{
  width:375px;
  border: none;
  padding:0 15px 15px;
  margin: 30px 0 0;
  clear: both;
  color: #666;
}
#crm-container .crm-socialnetwork h3{
  padding: 10px 0 6px 2px;
  margin:0;
  color: #13996a; /*e6661d*/
  font-weight: normal;
}
#crm-container .crm-socialnetwork .description{
  display: none;
}
#crm-container .crm-socialnetwork .label{
  display: block;
  background: transparent;
  float: left;
  width: 100%;
  min-height: 25px;
  text-align: left;
}
#crm-container .crm-socialnetwork iframe{
  height: 25px !important;
}

/* event admin links and blocks */
#block-civicrm-1{
  clear: both;
}
#id_fulltext_search{
  margin:0;
}
#id_fulltext_search div{
  margin: 0 !important;
}
#id_fulltext_search #text{
  width: 115px;
}
#fulltext_submit{
  margin-top: -11px;
}
#fulltext_table{
  margin-top: -5px;
}

/* event registration */
#crm-container .register_link-section a.button {
  @include bootstrap-button-info();
}
#crm-container .crm-section.summary{
  padding:0;
}
#crm-container #Register{
  background-color: #fafafa;
  clear: both;
  float: left;
  margin: 0 0 20px;
  width: 100%;
}
#crm-container #Register .crm-section{
  margin: 0;
}
#crm-container #Register .label,
#crm-container #Register .field-label{
  color:#333;
  margin: 0;
}
#crm-container #Register .field-label label{
  display: inline-block;
  padding: 5px 0 0 3px;
}
#crm-container #Register .crm-section div.content{
  padding: 1px;
}
#crm-container #Register .crm-section .form-radio{
  margin: 0 5px 0 0;
}
#crm-container #Register .form-inline{
  margin-left: 5px;
}
#crm-container #Register .form-inline label{
  margin: 0 5px 0 0;
}
.page-civicrm-event-register {
  .payment_processor-section {
    /* "Payment Method" label*/
    > .label {
      float: none;
      label {
        display: block;
        text-align: left;
      }
    }
    /* Payment Method radio buttons*/
    .content {
      input[type="radio"] {
        margin-top: 0;
        margin-right: 3px;
      }
      label {
        margin-right: 10px;
      }
      .crm-error {
        display: block;
        margin-top: 5px;
      }
    }
  }
}
#noOfparticipants{
  margin: 0!important;
}
#noOfparticipants .content{
  margin: 0 0 0 4px !important;
  float: left;
  width: 70%;
}
#noOfparticipants .content select{
  margin-bottom: -7px;
}
#noOfparticipants .description{
  display: block;
  max-width:500px;
  float: left;
  margin: -3px 0 0 66px;
}
.priceset-help{
  padding: 0 0 15px;
}
#crm-container span.crm-button_qf_Main_upload input,
#crm-container span.crm-button_qf_Confirm_next input,
#crm-container span.crm-button_qf_Register_upload input{
  font-size:14px;
}


/* registration confirmation page */
#Confirm .row{
  margin-left: 0;
}
#crm-container #Confirm .crm-submit-buttons{
  float: none;
  margin: 20px 0;
}
#crm-container #Confirm div.display-block {
  margin: 0;
}
#crm-container .crm-button-type-cancel,
#crm-container .crm-button-type-back {
  margin-left: 0;
}
#crm-container #Confirm .crm-section{
  margin: 0;
}
#crm-container #Confirm .crm-section .field-label,
#crm-container #Confirm .crm-section .content{
  padding:0;
}
#crm-container #Confirm .crm-section .field-label label{
  color: #666;
  font-weight: bold;
}


/* registration thank you page */
#crm-container #ThankYou .event-info-wrapper .event-info-detail {
  min-height:20px;
  margin-left: 2.564102564102564%;
}
#crm-container #ThankYou .crm-section .field-label,
#crm-container #ThankYou .crm-section .content{
  padding:0;
}

/* scholarship form */
.crm-contribution-page-id-1 #priceset .contribution_amount-section .field-label.span3{
  display: none;
}
.crm-contribution-page-id-1 #priceset .other_amount-section .field-label{
  float: left;
  margin-right: 10px;
  display: none;
}
#crm-container .crm-contribution-page-id-1 .crm-section .other_amount-content{
  margin-left: 23px;
}
#crm-container .crm-contribution-page-id-1 .crm-section div.content{
  padding: 0;
}
#crm-container .crm-contribution-page-id-1 .email-5-section{
  clear: both;
  margin-top: 15px;
  float: left;
  width: 100%;
  margin-left: 2px;
  margin-bottom: 15px;
}
#crm-container .crm-contribution-page-id-1 .email-5-section .label{
  float: none;
}
#crm-container .crm-contribution-page-id-1 .email-5-section label{
  font-size: 17px;
  font-weight: bold;
  line-height: 32px;
}
#crm-container .crm-contribution-page-id-1 .crm-section .label{
  color: #333;
  text-align: left;
  padding-left: 0;
}
#crm-container .crm-contribution-page-id-1 .payment_processor-section .label{
  padding-top: 0;
}
#crm-container .crm-contribution-page-id-1 .payment_processor-section .content label{
  padding-right: 19px;
}
.custom_pre_profile-group .span3{
  width:17%;
}
#crm-container .crm-contribution-page-id-1 #credit_card_type{
  width:125px;
}
#crm-container .crm-contribution-page-id-1 #credit_card_number{
  width: 215px;
}
#crm-container .crm-contribution-page-id-1 #cvv2{
  width: 50px;
}
#crm-container .crm-contribution-page-id-1 #credit_card_exp_date_M{
  width: 90px;
}
#crm-container .crm-contribution-page-id-1 #credit_card_exp_date_Y{
  width:100px;
}
.crm-contribution-page-id-1 #billingcheckbox{
  margin: 0 5px 0 12px;
}
#crm-container .crm-contribution-page-id-1 #crm-submit-buttons input#_qf_Main_upload-bottom {
  font-size: 15px;
  padding: 4px 12px;
}
