/* primary tabs for page options: i.e. edit, view, revisions, etc. */
#page-options{
  position: relative;
  z-index: 100;
}
#page-tabs{
  padding: 0
}
#page-tabs li{
  display: inline;
  margin:0;
  padding: 0;
  list-style: none;
}
.btn-group > li:first-child .btn{
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.btn-group > li .btn{
  margin-left: -1px;
  margin-right:0;
}
.btn-group > li .btn{
  font-size: 14px;
}
.btn-group > li .btn {
  position: relative;
  border-radius: 0;
}
.btn-group > li:last-child .btn{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
