/* site header */
#logo{
  margin: 10px 0 5px 10px;
  max-width: 205px;
}
#tagline{
  float: left;
  display: none;
  margin-top: 46px;
  margin-left: -17px;
}
#logo a{
  float: left;
}
#logo img{height:auto;max-width:100%;}

#navbar .navbar-inner{
  padding-bottom:0;
  background-color:#C7E3F8; /* cae1f3 */
  background-image: -webkit-linear-gradient(top, #C7E3F8, #EDF6FC);
  background-image: -moz-linear-gradient(top, #C7E3F8, #EDF6FC);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#C7E3F8), to(#EDF6FC));
  background-image: -webkit-linear-gradient(top, #C7E3F8,#EDF6FC);
  background-image: -o-linear-gradient(top, #C7E3F8,#EDF6FC);
  background-image: linear-gradient(to bottom, #C7E3F8,#EDF6FC);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffC7E3F8', endColorstr='#ffEDF6FC', GradientType=0);
}
.navbar .btn-navbar {position: absolute;right: 0;top: 70px;}
#navbar .container{position:relative}
#navbar .brand{height:86px;padding:15px 20px 0 30px;display: block;overflow: hidden;}
#navbar .brand img{height:96px;width:551px}
#search-block-form{margin-bottom: 5px}
#search-block-form .form-actions{
  padding: 0;
}
#search-block-form .form-submit{margin-bottom:6px;color:#5E83A8;background: #F0F5FA}
#edit-search-block-form--2{
  background: #fafafa;
  max-width: 165px;
}
#block-search-form{margin:-2px 0 0;float:right}
#navbar #block-user-login{position: relative;float: right;}
#block-search-form div{display: inline!important;float:left;}
#block-search-form .form-item-search-block-form{margin: 5px 2px 0 0;}
#block-search-form form{margin: 0 15px 0 0}
#block-user-login form{margin:0}
#block-user-login .btn{margin: 0}
.admin-menu #block-search-form{margin-top:0;}
#header{background: #fff;border:none;padding: 0}
#main{padding-top:10px;}
.block-service-links{float: right;margin:3px 0 0;position: relative;}
#toboggan-login-link{padding-top:7px;padding-bottom: 7px;font-size:13px;}
#gplus-wrapper{display: inline;visibility: hidden;}
.block-service-links h2{display: none;}
.block-service-links ul{margin: 0;padding: 0}
.block-service-links li{display: inline;margin: 0;padding: 0}

/* user menu (after login) */
#block-system-user-menu {float:right;margin:0;clear:both;}
#block-system-user-menu a{margin: 0 !important;}
#block-block-6{margin:0;float: right;padding: 10px 10px 0;}
.logged-in #navbar #block-service-links-service-links,
.logged-in #navbar #block-service-links-service-links-not-node{margin-right: 0}/* move social icons to the right after login */
.navbar #block-system-user-menu .nav {margin: 0;padding:0;}

/* main nav menu */
ul.nice-menu-down li,.nav-tabs > li {
  margin-bottom: -6px;
}
.navbar .nav > li > a {
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-shadow:none;
}
#navbar #block-nice-menus-1 .nav{
  background:transparent;
  margin:20px 0 0 15px;
}
.logged-in #navbar #block-nice-menus-1 .nav{
  margin-top: 10px
}
#navbar #block-nice-menus-1 li a.active{
  background-color:#fff!important;
  color:#565656!important;
  border-radius:3px 3px 0 0;
  border: none;
  padding-bottom: 12px;
  margin-bottom: 0;
  box-shadow: inset 0 1px 1px #ccc;
}
#navbar #block-nice-menus-1 li a:hover{
  background-color:#f79533;
  color:#fff;
}
.block-nice-menus .dropdown-menu {
  border-radius: 0;
  border:1px solid #ddd;
  min-width: 190px;
}
ul.nice-menu ul.dropdown-menu li {
  width: auto;
  min-width: 160px;
}
ul.nice-menu li,ul.nice-menu-down li.menuparent{
  border: none;
  background: transparent;
}
ul.nice-menu-down ul {
  top:40px;
}
.dropdown-menu li > a {
  padding: 7px 20px!important;
  min-width: 160px;
}
.navbar .nav li.menuparent > a .caret {border-top-color: rgb(119, 119, 119);border-bottom-color: rgb(119, 119, 119);}
.navbar .nav a .caret {margin-top: 8px;}
.nav .dropdown-toggle .caret {margin-top: 6px;border-top-color: rgb(0, 136, 204);border-bottom-color: rgb(0, 136, 204);}
.menuparent .caret {margin-top: 8px;margin-left: 2px;}
#navbar #block-nice-menus-1 li ul a:hover{
  background-color:#888;
  color:#fff;
  text-shadow:0 1px 0 #bbb;
  -webkit-box-shadow:none;
  -moz-box-shadow:none;
  box-shadow:none;
}
ul.nice-menu-down li.menuparent:hover {background: none;}
