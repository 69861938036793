/* sidebar */
.well ul{margin:0 0 10px;}
#sidebar_first .nav,#sidebar_second .nav{
  padding: 0 0 0 5px;
}
#sidebar_first .block{
  clear: both;
}
#sidebar_first li{
  margin: 0 0 8px;
}

