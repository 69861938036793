/* footer */
#footer{
  margin-top: 35px
}
#footer .container{
  border-top: 1px solid #ddd;
  padding: 20px 0 0
}
#block-nice-menus-2{
  margin: 0
}
#footer p{
  margin:0;
  padding:0.3em 0;
}
