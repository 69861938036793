/* general typography */
p{
  line-height: 20px !important;
  margin:0 0 10px !important;
}
blockquote{
  border-left: none;
}
blockquote p {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
}
a:hover{
  text-decoration: none !important;
}

/* Merge Bootstrap styles back in */
#crm-container fieldset legend {
  display: block!important;
  width: 100%!important;
  padding: 0!important;
  font-size: 17px!important;
  line-height: 32px!important;
  color: #333!important;
  border: none!important;
  font-weight: bold!important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif!important;
}
.icon{
  color:#2786C2;
}
.item-list .unstyled ul li{
  margin: 0 0 10px;
  padding: 0;
}
td.label {
  display: table-cell !important;
  color: #444;
}
label{
  color: #444;
}
.crm-section.group_selection-section .label {
  color: #444;
}
.crm-accordion-wrapper {
  min-height: 20px;
  padding: 0px;
  background-color: whiteSmoke;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/* replace JQuery UI icons */
/* to override jqueryui icons for font icons */
[class^="icon-"],
[class*=" icon-"] {
  background: none !important;
  background-image: none !important;
  text-indent: 0 !important;
  padding: .2em .6em 0 .2em !important;
}


/* general form tweaks */
.form-item .description {max-width: 660px;}
.resizable-textarea textarea {width: 100%;}
.form-item-timezone select{width: auto;}


/* messages/alerts */
.messages.alert{
  margin: 0 0 20px!important;
  border: 1px solid #FBEED5;
  max-width:660px;
}
.alert-info {
  color: #3A87AD!important;
  background-color: #D9EDF7!important;
  border-color: #BCE8F1!important;
}
#crm-container #help{
  font-size: 14px;
  margin: 0 0 20px;
  padding: 8px 35px 8px 14px;
}

/* some typography */
.page-header #page-title{
  font-size:28px;
  font-weight: normal;
  margin-top: 20px;
  padding-left:5px;
}
.page-training-view .page-header,.section-training.page-views .page-header{
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

/* breadcrumb */
.breadcrumb {padding: 8px 15px;margin: 10px 0 20px;background-color: #f5f5f5;}

/* page-specific header */
#header h1{font-size:24.5px}
.front .page-header{display: none;}

/* typography tweaks */
h1,h1.title, h2.node-title {
  font-size:32px;
  color:#595959;
  margin-top:30px;
}
h2{
  font-size:18.5px;
}
.well h2{
  font-weight: normal;
  color:#555;
  line-height: normal;
  margin-left: 5px;
}
