/* slideshow */
#block-views-front-slideshow-block{
  margin: 16px 0 0;
  position: relative;
  z-index: 1;
}
#block-views-front-slideshow-block img{
  margin:0 auto;
  display: block;
}

/* front page */
.front #main #content{
  padding:0;
}
.front .node-1173 .links.inline{
  display: none;
}
#block-block-8{
  margin:15px 2%;
  padding:2px 15px;
  border: 1px solid #E2F2F8;
  border-radius: 4px;
  background-color: #E2F2F8;
  background-image: -moz-linear-gradient(#F5FBFF, #E2F2F8);
  background-image: -webkit-linear-gradient(#F5FBFF, #E2F2F8);
  background-image: linear-gradient(#F5FBFF, #E2F2F8);
}
#block-block-8 h2{
  color: #666;
  margin: 6px 0 3px;
  font-size:18px;
}
#block-block-8 p{
  margin: 0 0 10px
}
#block-block-8 img{
  float: right;
  height: auto;
  margin-left: 15px;
  margin-top: -15px;
}
#block-views-cevent-base-block-2{
  float: left;
  margin: 15px 2.5%;
  padding:0 10px;
  width: 93%;
}
#block-views-cevent-base-block-2 h2{
  color: #F79533}
#twitter-widget-1{
  min-width: 100px !important;
}
#crm-event-block{
  clear: both;
  float: left;
  padding:20px 0 10px;
}
#crm-event-block p{
  float: left;
  width:167px;
  padding:0;
  margin:0 5.25% 15px 0;
  height:135px;
}
.front #block-block-3,
.front #block-views-most-recent-blog-post-block{
  padding: 12px;
  border: 1px solid #dedede;
  border-radius: 4px;
  background-image: -moz-linear-gradient(#eff2fc, #f9f9f9);
  background-image: -webkit-linear-gradient(#eff2fc, #f9f9f9);
  background-image: linear-gradient(#eff2fc, #f9f9f9);
}
.front #block-block-3{
  background-color: #F2F2F2;
  background-image: -moz-linear-gradient(#f2f2f2, #efefef);
  background-image: -webkit-linear-gradient(#f2f2f2, #efefef);
  background-image: linear-gradient(#f2f2f2, #efefef);
}
.front #block-block-3 h2,
.front #block-views-most-recent-blog-post-block h2{
  color: #666;
  font-size: 18px;
  line-height: normal;
}
.front #block-views-most-recent-blog-post-block{
  background: #EFF2FC;
}
.front #block-block-3 ul{
  padding: 0 5px 20px 5px;
  margin: 0;
}
.front #block-block-3 li{
  list-style: none;
  margin: 0 0 0 14px;
  padding: 5px 0;
  text-indent: -17px;
  line-height: normal;
}
#block-block-5{
  margin-top:20px;
}

/* Event displays - sidebars */
#crm-quick-create form{
  margin: 0;
}
#crm-quick-create label{
  margin: 8px 0 0 3px;
}
#crm-quick-create .form-submit{
  margin-top: 6px;
}

/* Login reminder block */
.lower .form-item{
  margin: 10px 0;
}
.lower .item-list{
  display: none;
}
.lower{
  top:240px;
}


/* My events block */
#block-views-pevents-block{
  clear: both;
}
#block-views-pevents-block ul{
  padding: 0;
  margin: 0 0 10px;
}
#block-views-pevents-block li{
  color: #666;
  margin: 0 0 10px;
}

/* Twitter panel */
#block-block-5 .ext{
  display: none;
}
