// Breakpoints
$screen-s: 479px;
$screen-m: 767px;
$screen-l: 979px;
$screen-xl: 1200px;

$color-green: #8faa6B; 
$color-off-white: #f4f4f4;
$color-gray-light: #cdcdcd;

