/* blogs */
.tagclouds-term{
  float: left;
  margin:0 10px 15px 0;
}
#block-tagclouds-2 .more-link{
  clear: both;
}
.node .submitted, article .submitted {
  margin:0 10px 20px 20px;
}
article.node-teaser {
  max-width: 700px;
}
.section-blog .node-teaser .submitted{width:150px;}
.blog-teaser p{margin:12px 0 0;}

/* blogs theming */
.field-name-field-blog-tags,.field-name-field-blog-categories {
  float: left;
  width: 100%;
  margin: 10px 0 20px;
}
 .field-name-field-blog-tags .field-label,.field-name-field-blog-categories .field-label {
  font-weight: normal;
  font-size: 17px;
  margin: 8px 0;
}
.field-name-field-blog-tags .field-item a,.field-name-field-blog-categories .field-item a{
  display: block;
  float: left;
  margin: 2px 5px 5px 0;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #2D6987;
}

