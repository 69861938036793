/* training list */
.page-views .feed-icon{
  margin-right: 30px;
  float: right;
}
.view-id-cevent_base .item-list ul{
  margin: 5px 0 20px;
  padding: 0;
  float: left;
  clear: both;
  width: 100%;
  max-width:760px;
}
.view-empty{
  margin:20px 30px;
}
.not-front .view-id-cevent_base .item-list ul{
  min-width:660px;
}
.not-front .sidebar .view-id-cevent_base .item-list ul{
  min-width:150px;
}
.view-id-cevent_base li{
  float: left;
  width: 98%;
  clear: both;
  padding: 8px!important;
  margin:0!important;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.view-id-cevent_base .pagination li{
  float: none;
  width: auto;
  clear: none;
  border: none;
}
.view-id-cevent_base .views-row-odd{
  background-color: #f9f9f9;
}
.view-id-cevent_base .views-field-title{
  float:left;
  margin:0 10px 0 0;
  font-weight: bold;
  max-width: 81%
}
.view-id-cevent_base .views-field-event-type{
  float: right
}
.view-id-cevent_base .views-field-start-date,
.view-id-cevent_base .views-field-friendly-date-6{
  clear: both;color:#575757;
}
.view-id-cevent_base .views-field-friendly-date-6{
  float:left;
  clear: left;
}
.view-id-cevent_base .views-field-summary p{
  margin: 0;
}
#block-menu-menu-training-menu ul{
  padding: 10px 0 0
}
