@mixin bootstrap-button-base() {
  appearance: none;
  display: inline-block;
  padding: 4px 12px;
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-repeat: repeat-x;

  &:hover,
  &:focus {
    text-decoration: none;
    transition: background-position 0.1s linear;
  }

  &:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  &.active,
  &:active {
    background-image: none;
    outline: 0;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  }
}
@mixin bootstrap-button() {
  @include bootstrap-button-base();
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  border: 1px solid #cccccc;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #b3b3b3;
  
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.disabled,
  &[disabled] {
    color: #333333;
    background-color: #e6e6e6;
  }

  &:active,
  &.active {
    background-color: #cccccc;
  }

  &:hover,
  &:focus {
    color: #333333;
  }
}

@mixin bootstrap-button-info() {
  @include bootstrap-button-base();
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.disabled,
  &[disabled] {
    color: #ffffff;
    background-color: #2f96b4;
  }

  &:active,
  &.active {
    background-color: #24748c;
  }

  &.active,
  .btn-inverse.active {
    color: rgba(255, 255, 255, 0.75);
  }
}
