/* CiviCRM events calendar */
.page-events-month.sidebar-second #content{width: 77%}
.page-events-month.sidebar-second .region-sidebar-second {width: 23%}
.page-events .page-header{padding-bottom: 0;margin-bottom: 10px}
.view-id-civicrm_events.view-display-id-page .pager{margin: 0}
.view-id-civicrm_events.view-display-id-page .calendar-calendar .view-item-civicrm_events{padding:2px;}
.calendar-calendar .month-view .full td.single-day div.monthview, .calendar-calendar .week-view .full td.single-day div.weekview, .calendar-calendar .day-view .full td.single-day div.dayview {
background: #fff;}
.calendar-calendar .month-view .full td.single-day .inner div.alert,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div.alert{
  padding: 8px;
  background-color: #178ACC !important;
  border: 1px solid #EFB99E;
  color:#fff;
  text-shadow:none;
  border-radius: 4px;
}
.view-id-civicrm_events.view-display-id-page .calendar-calendar .view-item-civicrm_events .contents a{
  color: #fff;
  text-decoration: none;
  font-weight: normal;
  padding:0;
}
.view .date-nav-wrapper .date-nav {margin: 0}
.view .date-nav-wrapper .date-prev,.view .date-nav-wrapper .date-next{background-color: transparent;
  border-radius: 0;}
.view .date-nav-wrapper .date-prev{right:auto;left:0;}
.calendar-calendar div.calendar {font-size: .95em}
.calendar-calendar .month-view .full tr td.today{border-left: 1px solid #ccc;border-right: 1px solid #ccc;background: #ffffcc}
.calendar-calendar .month-view .full td.date-box.today {border-width: 1px 1px 0 1px;border-style: solid;border-color: #ccc;}
.calendar-calendar .month-view .full tr td.single-day.today{border-bottom: 1px solid #ccc}
#block-views-civicrm-events-block-2{margin:60px 0 0 10px}
.upcoming-date-wrapper{
text-align: right;
display: inline-block;
border-left: 4px solid #EEE;
padding-left: 5px;
float: left;height: 41px;
width: 50px;
font-size: 16px;
margin-right: 10px;}
.upcoming-date-title{margin: 0 0 10px 90px;}
.view-display-id-block_2 ul{margin: 0;padding: 0}
.calendar-calendar .week-view .full div.single-day div.weekview, .calendar-calendar .day-view .full div.single-day div.dayview {
  border: 1px solid #DDD;
}

/* training calendar event labels */
.views-field-event-type .label{
  width:84px;
}
.views-field-event-type .Online,
.calendar-calendar .month-view .full td.single-day .inner div.Online,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div.Online{
  background-color: #178ACC !important;
  font-weight: normal;
  color:#fff;
  text-shadow:none;
  padding:10px;
}
.view-header .Online{
  background-color: #178ACC;
}
.views-field-event-type .Webinar,
.calendar-calendar .month-view .full td.single-day .inner div.Webinar,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div.Webinar{
  background-color: #AF82D8 !important;
  color: #fff !important;
  font-weight: normal;
  text-shadow:none;
  padding:10px;
}
.view-header .Webinar{
  background-color: #AF82D8;
}
.calendar-calendar .month-view .full td.single-day .inner div.On-Ground,
.views-field-event-type .On-Ground,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div.On-Ground{
  background-color:#F183AF !important;/*ee6e7d*/
  color: #fff !important;
  font-weight: normal;
  text-shadow:none;
  padding:10px;
}
.view-header .On-Ground{
  background-color: #F183AF;
}
.views-field-event-type .Online,
.views-field-event-type .Webinar,
.views-field-event-type .On-Ground{
  padding: 5px;
}
