@mixin row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
@mixin span-base {
  margin-left: 2.564102564102564%;
  margin-right: 2.564102564102564%;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
@mixin span12 {
  @include span-base;
  width: 100%;
}
@mixin span11 {
  @include span-base;
  width: 91.45299145299145%;
}
@mixin span10 {
  @include span-base;
  width: 82.90598290598291%;
}
@mixin span9 {
  @include span-base;
  width: 74.35897435897436%;
}
@mixin span8 {
  @include span-base;
  width: 65.81196581196582%;
}
@mixin span7 {
  @include span-base;
  width: 57.26495726495726%;
}
@mixin span6 {
  @include span-base;
  width: 48.717948717948715%;
}
@mixin span5 {
  @include span-base;
  width: 40.17094017094017%;
}
@mixin span4 {
  @include span-base;
  width: 31.623931623931625%;
}
@mixin span3 {
  @include span-base;
  width: 23.076923076923077%;
}
@mixin span2 {
  @include span-base;
  width: 14.52991452991453%;
}
@mixin span1 {
  @include span-base;
  width: 5.982905982905983%;
}
