/* book navigation */
.book-nav-horizontal .well{margin:10px 30px 40px;float: left;}
.book-nav-horizontal .nav{margin: 0;padding: 0}
.book-nav-horizontal .pager-wrapper{margin:45px 0 0 25px;clear: both;}
#block-book-navigation h2{line-height: 30px;margin-left:-5px;margin-bottom: 5px}
#block-book-navigation ul.nav li {
  padding: 0;
  text-indent: -20px
}
#block-book-navigation li [class^="icon-"],#block-book-navigation .nav li [class^="icon-"],
#block-book-navigation li [class*=" icon-"],#block-book-navigation .nav li [class*=" icon-"] {width:1.75em;}
#block-book-navigation .nav{margin: 0 0 10px 17px;padding: 0}
#block-book-navigation .nav .nav{margin: 5px 0 10px 18px}
#block-book-navigation li{list-style: none!important;}
#block-book-navigation li a.active {color: #474747;display: inline;}
#block-book-navigation .nav > li > a {display: inline;}
.node-type-book .pager{padding: 0}
#book-internal-nav a{max-width:175px;overflow: hidden}
#block-book-navigation .icon-file{color:#999;padding-right: .28em !important}
.node-book .links.inline{
  margin: 20px 0;
}

/* book content */
#content .node-book .field-name-body{min-height:300px;}
.node-type-book #content h1, .node-type-book #content .region-content{margin-left: 10px}


