/* CiviCrm merge with Boostrap */
#crm-container h3 {
    background-color: transparent;
    color: inherit;
    font-family: inherit;
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 10px;
    padding: 0;
}
.page-civicrm input.form-checkbox, .page-civicrm input.form-radio {
    margin: 3px 5px 0 0;
    vertical-align: top;
}
#crm-container .label{
  background-color: transparent;
  display: block;
  text-shadow: none;
  white-space: normal;
  line-height: normal;
  font-size: 14px;
  border: none;
  font-weight: bold;
}
.crm-container .crm-form-block,
.crm-form-block {
  background-color: #F6F6F6;
  padding: 10px;
}
#crm-container tr {
  font-size:14px;
}
#crm-container .crm-error{
  border-radius: 4px 4px 4px 4px;
  margin-bottom: 20px;
  padding: 8px 35px 8px 14px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
div.messages ul{
  margin: 10px 10px 10px 30px;
}
#crm-contact-actions-link .icon, 
#crm-participant-link .icon, 
#crm-event-links-link .icon, 
#crm-contribpage-links-link .icon, 
#crm-create-new-link .icon, 
.button .icon, 
.crm-button .icon {
  background-image: url("../images/ui-icons_2e83ff_256x240.png");
}
.ui-wdget{
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
}
#crm-container .panel li a:hover, #crm-container .crm-participant-list-inner li a:hover, #crm-container .crm-event-links-list-inner li a:hover, #crm-container .crm-contribpage-links-list-inner li a:hover {
    background-color:transparent;
    color: #005580;
    text-decoration: none;
}
.crm-container .crm-actionlinks-top:after {
  content: "";
  display: table;
  clear:both;
}

/* event management */
#mainTabContainer{
  clear: both;
}
.page-civicrm-event-manage #mainTabContainer {
  border: none;
  background: none;
}
.page-civicrm-event-manage #mainTabContainer ul.ui-widget-header {
  border: none;
  background: none;
}
#mainTabContainer .ui-tabs-nav {
  background-color: transparent;
}
#mainTabContainer .crm-tab-button a{
  margin: 0;
}
#mainTabContainer .nav li a:hover {
  background-color: #888888;
  color: #fff !important;
}
#mainTabContainer li.crm-tab-button {
  background-color: #f1f1f1;
  font-size: 13px;
  margin: 0 4px;
  padding: 0;
  background-image: none;
  border: none;
}
#mainTabContainer li.ui-state-active {
  background-color: #888;
}
#mainTabContainer li.ui-state-active a:link{
  background-color: #888;
  color: #fff!important;
}
#crm-container a.button,
#crm-participant-link,
#crm-event-links-link,
#crm-contribpage-links-link,
#crm-contact-actions-link,
#crm-container a.button:link,
#crm-container input.form-submit,
#crm-container input[type="button"],
#crm-container input.form-submit-inline,
#crm-container .crm-button {
  padding: 2px 10px;
  font-size:14px;
  border-radius: 3px;
  margin-bottom: 5px;
  margin-top: 5px;
  line-height: 20px;
  color: #333;
  text-align: center;
  text-shadow: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: #4F75AD;
  background-image: none;
  border: none;
  box-shadow: none;
  white-space: nowrap;
  position: relative;
}
.crm-container a.button, .crm-container a.crm-invoiceButton, .crm-container a.crm-invoiceButton:link, .crm-container a.crm-invoiceButton:visited, .crm-container a.button:link, .crm-container a.button:visited, .crm-container input.crm-form-submit, .crm-container .ui-dialog-buttonset .ui-button, .crm-container input[type=button], .crm-container .crm-button {
  text-shadow: none;
  background: #4F75AD;
  color: #FFF;
  font-size: 13px;
  font-weight: normal;
  margin: 0 6px 0 0;
  padding: 4px 8px;
  text-decoration: none;
  cursor: pointer;
  border: none;
}
.crm-container .crm-button-icon {
  top: 1px;
  left: 4px;
}
.crm-container .crm-search-form .crm-button-icon {
  top:7px;
  left: 7px;
}
.page-civicrm-event-manage .crm-container .crm-button-icon {
  top: 9px;
  left: 6px;
}
.crm-icon-button {
  position: relative;
}
#crm-container .crm-icon-button input{
  padding: 5px 10px 5px 23px;
  border: none;
  background-image: none; 
}
#crm-container .crm-button-type-cancel {
  background-color: #AF5353;
}

.page-civicrm-event-manage #actions{
  position: relative;
}
.page-civicrm-event-manage #actions .helpicon{
  left:-20px;
  background-image: none;
  font-size: 16px;
}
.crm-participant-form-block-role_id label{
  padding-right: 10px;
}
#crm-contact-actions-link .dropdown-icon{
  float: left;
}
.ac_results {
  background-image: none;
  @include dropdown-menu();
}
#crm-event-links-wrapper .ac_results li a{
  color: #2786c2;
}
#crm-event-links-wrapper .crm-event-links-list-inner,
#crm-event-link-wrapper .crm-participant-list-inner{
  background-color: #fff;
}
#crm-event-links-list, 
#crm-participant-list {
  top:32px;
}
#crm-participant-link:hover .icon, 
#crm-event-links-link:hover .icon{
  background-position: -66px 0;
  margin-top: 4px;
}
.page-civicrm-event-manage #crm-event-links-wrapper,
.page-civicrm-event-manage #crm-participant-wrapper {
  padding: 5px 10px;
  background: #eee;
  margin-left: 10px
}
#crm-event-links-wrapper #crm-event-links-link,
#crm-participant-wrapper #crm-participant-link {
  background-color: transparent;
}
#crm-participant-list .crm-participant-list-inner {
  background: transparent;
}
.crm-container #crm-participant-list .crm-participant-list-inner li,
.crm-container #crm-participant-list .crm-participant-list-inner li a,
.crm-container #crm-participant-list .crm-participant-list-inner li a:visited {
  color: #555
}
.crm-container a.crm-event-feed-link .label {
  border: 0;
  clip: rect(1px,1px,1px,1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

/* search and find */
.crm-basic-criteria-form-block .label {
  padding-top: 15px;
}
