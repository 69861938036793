/* Account registration */
form#Register select{
  width:auto;
}
#user-register-form .crm-section .content {
  margin:0;}
#profilewrap14 fieldset{margin:0;padding: 0
}
#profilewrap14 legend{display: none}
#profilewrap14 .messages p{margin: 0}
#user-register-form .form-item{margin:12px 0 0;position: relative;}
#edit-field-user-biography{margin-top: 50px}
#user-register-form .form-item-name .description,
#user-register-form .form-item-mail .description{
  position: absolute;
  left:300px;
  top:5px;
  width: 65%
}
#user-register-form #field-user-biography-add-more-wrapper{max-width: 660px}
#user-register-form #edit-timezone{margin: 25px 0 0;padding: 0}
#user-register-form #edit-timezone legend a,#user-register-form #edit-timezone legend a:hover{
  color: #333;font-size: 14px;font-weight: bold;text-decoration: none;}
#user-register-form #edit-timezone legend span{padding: 0}
#user-register-form #edit-timezone .fieldset-wrapper{padding: 0}
#user-register-form #edit-timezone .description{top:63px;}
#user-register-form .form-actions{padding: 0;margin-top: 46px}
.mollom-privacy{
  margin: 10px 0;
}
#user-register-form .captcha{
  margin-top: 20px;
  max-width:450px;
  background:#f1f1f1;
}
#user-register-form .captcha legend{
  background-color:#c8ecc8;
  padding: 15px 20px 0;
  max-width: 410px;

}
#user-register-form .captcha .fieldset-wrapper{
  padding: 10px 20px 15px;
  max-width: 410px;
}
#user-register-form .captcha img{
  margin: 10px 0;
}
