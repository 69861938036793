/* login button */
#toboggan-login-link {
  background-color: #003769;
  background-image: linear-gradient(to bottom, #4f7ab9,#003769);
  background-repeat: repeat-x;
  border-color: #003769 #003769 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

/* login container */
#toboggan-container div.user-login-block {
  text-align:left;
  position:absolute;
  right:-2px;
  z-index:1000;
  background:#f9f9f9;
  padding:10px;
  border-radius:5px;
  border:1px solid #DDD;
  box-shadow:0 0 9px #ddd;
  margin-top:2px;
  min-height: 190px;
}
#toboggan-login label {
  color: #666
}
#toboggan-login ul {
  margin:55px 0 0;
  float: right;
}
#toboggan-login li {
  list-style:none;
  padding:0;
  text-align: right;
}
#toboggan-login a:hover {
  background-color:transparent!important;
  color:#005580!important
}
#toboggan-login #edit-actions {
  position: absolute;
}
#toboggan-login #edit-actions #edit-submit {
  margin-top:10px;
}
#block-user-login #edit-actions--2{
  position: absolute;
}
div.toboggan-login-link-container {
  width: 140px;
  text-align: right;
}
